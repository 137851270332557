import en from './en.json';
import ja from './ja.json';
import fr from './fr.json';
import sv from './sv.json';
import es from './es.json';
import fc from './fc.json';
import de from './de.json';
import ko from './ko.json';

const translations = {
    en,
    ja,
    fr,
    sv,
    es,
    fc,
    de,
    ko
};

export default translations;
